export function clone(obj) {
  if (null == obj || "object" != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
}
export function countPackageByCustomer(obj,customer) {
  let total = 0
  obj.map(item=>{
    if(item.Customer.Username === customer){
      total = ++total
    }
  })
  return total
}
export function serialize(obj) {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p) && obj[p] !== null && obj[p] !== undefined) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
export function toArray(obj, all = undefined){
  let result = [];
  if(all){
    result.push(all);
  }
  Object.keys(obj).map(key => result.push(obj[key]));
  return result;
}
