import timezone from './timezone'
import countries  from './countries.json'
export function pad(num, size) {
  var s = "000000000" + num;
  return s.substr(s.length-size);
}
export function tinhtien(cost,price){
  return parseInt(parseFloat(cost)*parseInt(price))
}
export function genShipmentName (type) {
  let TYPE = type.toUpperCase()
  if(TYPE === 'SHIP') TYPE = 'SEA';
  const MONTH = pad(timezone.now().format('M'),2);
  const WOM =  pad(timezone.wom(),2)
  return `${TYPE}${MONTH}${WOM}`
}
export function xoadau(str) {
  var AccentsMap = [
    "aàảãáạăằẳẵắặâầẩẫấậ",
    "AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ",
    "dđ", "DĐ",
    "eèẻẽéẹêềểễếệ",
    "EÈẺẼÉẸÊỀỂỄẾỆ",
    "iìỉĩíị",
    "IÌỈĨÍỊ",
    "oòỏõóọôồổỗốộơờởỡớợ",
    "OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ",
    "uùủũúụưừửữứự",
    "UÙỦŨÚỤƯỪỬỮỨỰ",
    "yỳỷỹýỵ",
    "YỲỶỸÝỴ"    
  ];
  for (var i=0; i<AccentsMap.length; i++) {
    var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
    var char = AccentsMap[i][0];
    str = str.replace(re, char);
  }
  return str;
}
export function DoiTien(Sotien,locale='vi-VN',currency='VND'){
  Sotien = Sotien ? Sotien:0;
  return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(Sotien)
}
export function Country(Code){
  return countries[Code]
}
export function ListCountries(){
  return Object.keys(countries).map((key) => ({
    'code':key,
    'text':countries[key]
  }))
}
export function LinkIMG(uri){
  if(uri.includes("tps://") || uri.includes("tps://")) return uri
  // else if(uri.split('/')[2] === "new") return `https://5002402-s3user.s3.cloudstorage.com.vn/tomexpress${uri}`
  else return `https://5002402-s3user.s3.cloudstorage.com.vn/tomexpress${uri}`
}
export function debounce(cb, delay = 1000) {
  let timeout

  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      cb(...args)
    }, delay)
  }
}
export function initials(string) {
  return string.trim().split(' ').map((w) => w[0]).join("").toUpperCase();
}
export const truncate = function (text, length=30, suffix='...') {
  if (text.length > length) return text.substring(0, length) + suffix;
  return text;
}