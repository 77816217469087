import { Request } from '@/plugins/helper/fetch'
import {serialize} from "@/plugins/helper/object";
const req = new Request('kho/kho-hang');
const qrList = (filter)=>req.fetch(`list?${serialize(filter)}`).then(data=>data.json());
const qrListNH = ()=>req.fetch(`nganh-hang`).then(data=>data.json());
const qrCreate = (CreateData) => req.put(`create`, CreateData).then(data=>data.json());
const qrCreateBatch = ({NH, SL, CKA, CKB, CK}) => req.put(`create-batch`, {NH, SL, CKA, CKB, CK}).then(data=>data.json());
const qrUpdate = (id, Request) => req.patch(`update/${id}`,Request).then(data=>data.json());
const qrDelete = (id) => req.remove(`/delete/${id}`).then(data=>data.json());
const qrNotes = (id, Notes) => req.patch(`notes/${id}`,{Notes}).then(data=>data.json());
const qrUploadFile = (uri, file, index) => req.UploadFile(uri, file).then(resp=>resp.data);
const qrRemoveImage = (id, images) => req.patch(`image/${id}`,{images}).then(data=>data.json());
const qrExportExcel = ({app,query})=>{
  return req.post('xuat-mau-excel',query).then(response=>{
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json().then(json => {
        const {error} = json;
        if(error){
          app.notice.error(error);
        }
      });
    } else {
      return response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "kho-hang-mau-nhap-hang.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    }
  });
}
const qrExcelImport = (items) =>req.patch(`import-excel`,{items}).then(data=>data.json());
const qrExportExcelBarcode = ({app,id, MaKho})=>{
  return req.post(`xuat-excel-barcode/${id}`,{export: true}).then(response=>{
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json().then(json => {
        const {error} = json;
        if(error){
          app.notice.error(error);
        }
      });
    } else {
      return response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${MaKho}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    }
  });
}
const qrRemove = (items) =>req.patch(`remove`,{items}).then(data=>data.json());
const qrRestock = (items) =>req.patch(`restock`,{items}).then(data=>data.json());
const qrConfirmExport = (items) =>req.patch(`confirm-export`,{items}).then(data=>data.json());
const qrExportExcelTracking = ({app, items})=>{
  return req.post(`xuat-excel-tracking`,{items}).then(response=>{
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json().then(json => {
        const {error} = json;
        if(error){
          app.notice.error(error);
        }
      });
    } else {
      return response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Tracking.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    }
  });
}
const qrUpdateTracking = (items) =>req.patch(`update-tracking`,{items}).then(data=>data.json());
const qrConfirmPayment = (items) =>req.patch(`confirm-payment`,{items}).then(data=>data.json());
const qrPDF = async (id, is3x4=false) =>{
  const QueryUri = is3x4 ? `label3x4/${id}`:`label/${id}`;
  return req.get(QueryUri).then(resp=>{
    if(resp.headers.get("content-type").toLowerCase() === "application/pdf"){
      return resp.blob()
    }
    else{
      return resp.json()
    }
  });
}
const qrTracking = (id) =>{
  return req.get(`tracking/${id}`).then(resp=>resp.json());
}

const qrExportExcelReport = ({app})=>{
  const Filter = {
    ...app.Filter,
    ...app.Options,
  }
  return req.post('xuat-bao-cao-qldh',Filter).then(response=>{
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json().then(json => {
        const {error} = json;
        if(error){
          app.notice.error(error);
        }
      });
    } else {
      return response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "quan-ly-don-hang.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    }
  });
}
export default {
  qrList,
  qrListNH,
  qrCreate,
  qrUpdate,
  qrDelete,
  qrNotes,
  qrUploadFile,
  qrRemoveImage,
  qrCreateBatch,
  qrExportExcel,
  qrExcelImport,
  qrExportExcelBarcode,
  qrRemove,
  qrConfirmExport,
  qrExportExcelTracking,
  qrUpdateTracking,
  qrConfirmPayment,
  qrPDF,
  qrTracking,
  qrRestock,
  qrExportExcelReport,
}