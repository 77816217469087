import moment from 'moment-timezone'
export const now = () =>{
  return moment().tz('Asia/Ho_Chi_Minh')
}
export const wom = (date) => {
  const m = date ? date : now()
  let weekInYearIndex = m.week();
  if (m.year() !== m.weekYear()) {
    weekInYearIndex = m.clone().subtract(1,'week').week() + 1;
  }
  const weekIndex = weekInYearIndex - moment(m).startOf('month').week() + 1;
  return weekIndex
}
export const sew = {s:now().startOf('isoweek').toDate(),e:now().endOf('isoweek').toDate()}
export const parseDateTime = (datetime) =>{
  const localtz = moment.tz.guess();
  return moment(datetime).tz(localtz).format("DD-MM-YYYY HH:mm")
}
export default {
  now,
  wom,
  sew,
  parseDateTime
}