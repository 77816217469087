import { Request } from '@/plugins/helper/fetch'
import {serialize} from "@/plugins/helper/object";
const req = new Request('kho/hoa-don');
const qrList = (filter)=>req.fetch(`list?${serialize(filter)}`).then(data=>data.json());
const qrCreate = (Request) => req.put(`create`, {data:Request}).then(data=>data.json());
const qrExport = ({app,id, MaHD})=>{
  return req.post(`xuat/${id}`,{export: true}).then(response=>{
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json().then(json => {
        const {error} = json;
        if(error){
          app.notice.error(error);
        }
      });
    } else {
      return response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `HD-${MaHD}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    }
  });
}
const qrUploadFile = (uri, file, index) => req.UploadFile(uri, file).then(resp=>resp.data);
const qrConfirmPaid = (id) => req.patch(`confirmPaid/${id}`,{confirm: true}).then
(data=>data.json());
const qrCancelPaid = (id) => req.patch(`cancelPaid/${id}`,{confirm: true}).then
(data=>data.json());
const qrPaymentError = (id) => req.patch(`PaymentError/${id}`,{confirm: true}).then(data=>data.json());
const qrCancel = (id) => req.patch(`Cancel/${id}`,{confirm: true}).then(data=>data.json());
const qrNotes = (id)=>req.fetch(`note/${id}`).then(data=>data.json());
const qrAddNote = (id, Content)=>req.put(`note/${id}`,{Content}).then(data=>data.json());
const qrConfirmExport = (items) =>req.patch(`confirm-export`,{items}).then(data=>data.json());
const qrConfirmCancelExport = (items) =>req.patch(`cancel-export`,{items}).then(data=>data.json());
const qrImages = (id)=>req.fetch(`images/${id}`).then(data=>data.json());
export default {
  qrList,
  qrCreate,
  qrExport,
  qrUploadFile,
  qrConfirmPaid,
  qrPaymentError,
  qrCancel,
  qrNotes,
  qrAddNote,
  qrConfirmExport,
  qrImages,
  qrCancelPaid,
  qrConfirmCancelExport,
}